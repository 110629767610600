// App.jsx
import React from "react";
import { Outlet } from "react-router-dom";
import CookieConsent from "./components/Cookies/CookiesConsent";

function App() {
  return (
    <div>
      <Outlet />
      <CookieConsent />
    </div>
  );
}

export default App;
