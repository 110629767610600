import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import a2sLogo from "./media/a2s-logo.png";
import CGS from "./media/CONDITIONS_GENERALES_DE_SERVICE_ET_DABONNEMENT_17012024.pdf";
import CGU from "./media/CONDITIONS_GENERALES_D_UTILISATION_05072021.pdf";

export const Footer = ({ isProLanding }) => {
  return (
    <div className="text-white bg-primary py-20 lg:py-40 text-center">
      {/* FOOTER LINKS */}
      <div className="flex justify-center">
        <p className="flex gap-2">
          <a
            href="https://monmedecin.org/index.php?option=com_content&view=article&id=60&Itemid=101"
            className="hover:underline"
          >
            Mentions Légales
          </a>
          /
          <a
            href={`${
              isProLanding
                ? CGS // professionnel
                : CGU // patient
            }`}
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Conditions générales{" "}
            {`${isProLanding ? "de services" : "d'utilisation"}`}
          </a>
        </p>
      </div>
      <div className="flex justify-center">
        <p className="flex gap-2">
          <a href="mailto:dpo@urpsml-na.org" className="hover:underline">
            Contacter le DPO
          </a>
          /
          <a href="mailto:contact@monmedecin.org" className="hover:underline">
            Contact
          </a>
          /
          <a
            href="https://www.linkedin.com/showcase/monmédecin/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} className="text-white text-xl" />
          </a>
        </p>
      </div>

      {/* A2S LOGO */}
      <div className="flex justify-center my-5">
        <a href="https://www.avis2sante.fr" target="_blank" rel="noreferrer">
          <img
            src={a2sLogo}
            alt="logo_footer"
            className="transition-all hover:scale-[1.05]"
          />
        </a>
      </div>

      {/* EMERGENCY INFO */}
      <div className="flex justify-center mt-8">
        <p className="font-semibold text-center">
          Ceci n'est pas un service d'urgence, en cas d'urgence appelez le 15 ou
          le 112
        </p>
      </div>

      {/* LIST SECTION*/}
      <div className="my-10 grid grid-cols-1 grid-rows-1 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 2xl:mx-96">
        <div>
          <ul>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=1&Itemid=813">
                Médecins généralistes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=57&Itemid=802">
                Allergologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=8&Itemid=866">
                Angiologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=34&Itemid=865">
                Anesthésistes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=2&Itemid=803">
                Cardiologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=3&Itemid=804">
                Dermatologues vénérologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=15&Itemid=805">
                Gynécologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=4&Itemid=867">
                Infectiologues
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=56&Itemid=868">
                Kinésithérapeutes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=55&Itemid=1207">
                Médecins du sommeil
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=65&Itemid=1065">
                Médecins du sport
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=5&Itemid=1050">
                Néphrologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=38&Itemid=869">
                Neurologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=12&Itemid=870">
                Nutritionnistes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=6&Itemid=871">
                Ophtalmologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=42&Itemid=872">
                ORL
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=60&Itemid=873">
                Orthophonistes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=16&Itemid=874">
                Pédiatres
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=9&Itemid=875">
                Pneumologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=11&Itemid=876">
                Psychiatres
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=45&Itemid=877">
                Psychologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=10&Itemid=878">
                Radiologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=21&Itemid=879">
                Rhumatologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=14&Itemid=880">
                Sophrologues
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=26&Itemid=881">
                Stomatologues
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=46&Itemid=882">
                Thérapeuthes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=64&Itemid=883">
                Thermalistes
              </a>
            </li>
            <li>
              <a href="https://monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&employee_group=23&Itemid=884">
                Urologues
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
