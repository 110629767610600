// router.jsx
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import { About } from "./About";
import { PreviewUI } from "./views/PreviewUI/PreviewUI";
import { LandingPatient } from "./views/Landing/LandingPatient";
import { LandingPro } from "./views/Landing/LandingPro";

export const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<LandingPatient />} />
        <Route path="about" element={<About />} />
        <Route path="preview-ui" element={<PreviewUI />} />
        <Route path="pro" element={<LandingPro />} />
      </Route>
    </Routes>
  </Router>
);

// export default AppRouter;
