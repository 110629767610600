import { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Buttons } from "../../components/UIElements/UIElements";
import hdsLogo from "./media/partners-logo/hds-logo.png"; // Logo de HDS
import urpsLogoHdf from "./media/partners-logo/urps-logo-hdf.png"; // Logo URPS Haut-De-France
import urpsLogoNa from "./media/partners-logo/urps-logo-na.png"; // Logo URPS Nouvelle-Aquitaine
import urpsLogoGE from "./media/partners-logo/urps-logo-ge.png"; // Logo URPS Grand-Est
import PanelImg1 from "./media/panel-img-1.png"; // Logo for Panel UP LEFT
import PanelImg2 from "./media/panel-img-2.png"; // Logo for Panel UP RIGHT
import PanelImg3 from "./media/panel-img-3.png"; // Logo for Panel DOWN LEFT
import PanelImg4 from "./media/panel-img-4.png"; // Logo for Panel DOWN RIGHT
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCalendar,
  faUserGroup,
  faShield,
  faPeopleGroup,
  faCreditCard,
  faComments,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import ansLogo from "./media/partners-logo/ans-logo.png";
import isoLogo from "./media/partners-logo/iso-logo.png";
import epesLogo from "./media/partners-logo/epes-logo.png";
import nrLogo from "./media/partners-logo/nr-logo.png";

// IMPORT DE LIBRAIRIES
import DocumentMeta from "react-document-meta";

export const LandingPro = () => {
  // Smooth scroll code snippet
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });

  // Pricing table code snippet
  const [pricingType, setPricingType] = useState("mensuel");

  // Code snippet to switch between differents words
  const [wording, setWording] = useState([
    "Agenda",
    "Téléconsultation",
    "Téléexpertise",
    "E-RCP",
    "Messagerie",
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        currentIndex === wording.length - 1 ? 0 : currentIndex + 1
      );
    }, 2000);

    setDisplayedWord(wording[currentIndex]);

    return () => clearInterval(interval);
  }, [currentIndex, wording]);

  const metaContent = {
    title: "MonMedecin.org • La solution de télémédecine éthique et solidaire",
    description:
      "MonMedecin.org est une plateforme complète de télémédecine et d'agenda, conçue pour répondre aux besoins des médecins libéraux et autres professionnels de santé, afin de faciliter leur exercice professionnel et d'améliorer l’accès aux soins.",
    canonical: "https://monmedecin.org/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "MonMedecin,Télémédecine,Téléconsultation,Téléexpertise,e-rcp,agenda,messagerie",
      },
    },
  };

  return (
    <div className="overflow-x-hidden">
      <DocumentMeta {...metaContent} />
      {/* GREY BACKGROUND */}

      {/* HEADER */}
      <Header isProLanding={true} />

      {/* HERO SECTION */}
      <div className="flex flex-col lg:flex-row mx-auto w-full px-2">
        <div className="flex w-full my-16 lg:ml-72">
          <div className="xl:mt-20 text-3xl text-center lg:text-start">
            <h2 className="font-medium mb-3 text-gray-800">
              La plateforme complète de télémédecine
            </h2>
            <h1>
              <span
                key={currentIndex}
                className="animate-slidingTextIn text-primary font-semibold text-4xl uppercase"
              >
                {displayedWord}
              </span>
            </h1>
            <p className="font-light text-xl mt-3 text-gray-600">
              Un réflexe simple et confraternel fourni par votre URPS Médecins
              Libéraux
            </p>
            <div className="mt-3">
              <Buttons
                preset="primary"
                text={"S'inscrire"}
                link={
                  "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=8&Itemid=181"
                }
                styles={"text-lg hover:-translate-y-1"}
              />
              <Buttons
                text={"En savoir plus"}
                link={"#about-us"}
                icon={faChevronRight}
                styles={"text-primary hover:underline text-lg"}
              />
            </div>
          </div>
        </div>

        {/* HERO IMAGES */}
        <div className="-z-[999] w-6/12 xl:w-7/12 h-4/5 -translate-x-28 sm:-translate-x-0">
          <div className=" w-[1000px] ml-20 rotate-[-15deg]">
            <div className="w-3/5 h-3/5 sm:w-2/3 sm:h-2/3 lg:w-11/12 lg:h-11/12 grid grid-cols-2 grid-rows-2 gap-2">
              <div className="shadow-lg bg-white rounded-lg border-gray-300 box-shadow-custom w-full h-4/5 flex self-end">
                <img src={PanelImg1} alt="Image de la solution 1" />
              </div>
              <div className="shadow-lg bg-white rounded-lg border-gray-300 w-[75%] ">
                <img
                  src={PanelImg2}
                  className=" w-full"
                  alt="Image de la solution 2"
                />
              </div>

              <div className="shadow-lg bg-white rounded-lg border-gray-300 w-[50%] ">
                <img
                  src={PanelImg3}
                  className="w-full "
                  alt="Image de la solution 3"
                />
              </div>
              <div className="shadow-lg bg-white rounded-lg border-gray-300 w-[125%]  transform -translate-x-[40%]">
                <img
                  src={PanelImg4}
                  className="w-full "
                  alt="Image de la solution 4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WHAT DO WE OFFER */}
      <div
        id="about-us"
        className="text-center py-20 lg:py-60 bg-primary text-white"
      >
        <div className="px-2 h-4/5 mx-auto w-full">
          <small className="font-light text-[20px]">QUE PROPOSONS NOUS ?</small>
          <p className="text-3xl font-bold mt-6 mb-3">
            La plateforme de télémédecine et d'agenda
          </p>
          <p className="font-light text-xl md:w-1/2 mx-auto">
            Conçue pour répondre aux besoins des médecins et autres
            professionnels de santé,
            afin de faciliter leur exercice professionnel et d'améliorer
            l’accès aux soins
          </p>
        </div>
      </div>

      {/* FUNCTIONALITIES SECTION */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 py-20 px-8 bg-greys-10/50">
        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-400 p-10 w-full xl:w-auto text-center space-y-3">
          <FontAwesomeIcon
            icon={faCalendar}
            className="text-primary text-4xl"
          />
          <p className="font-bold text-2xl">Agenda compatible SAS</p>
          <p className="font-light text-xl min-h-16 ">
            Gestion des RDV en ligne
            Agenda individuel ou partagé avec notifications
            <br /> par courriel et SMS
          </p>
        </div>

        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-400 p-10 w-full xl:w-auto text-center space-y-3">
          <FontAwesomeIcon icon={faShield} className="text-primary text-4xl" />
          <p className="font-bold text-2xl mx-auto">Téléconsultations sécurisées</p>
          <p className="font-light text-xl min-h-16 ">
            Immédiates : simples en 3 clics
            <br />
            Programmées grâce à l'agenda
            <br />
            Partage de documents
          </p>
        </div>

        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-400 p-10 w-full xl:w-auto text-center space-y-3">
          <FontAwesomeIcon
            icon={faUserGroup}
            className="text-primary text-4xl"
          />
          <p className="font-bold text-2xl mx-auto">Téléexpertise</p>

          <p className="font-light text-xl min-h-16 ">
            Demandes d'avis toutes spécialités
            <br /> Formulaires médicaux spécialisés
            <br /> Messagerie intégrée au dossier
          </p>
        </div>
        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-500 p-10 w-full xl:w-auto text-center space-y-3">
          <FontAwesomeIcon
            icon={faPeopleGroup}
            className="text-primary text-4xl"
          />
          <p className="font-bold text-2xl mx-auto">E-RCP sécurisées</p>
          <p className="font-light text-xl min-h-16 ">
            Organisation de réunions de concertation
            <br /> pluridisciplinaires en ligne
            <br /> Nombre illimité de participants
          </p>
        </div>

        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-400 p-10 w-full xl:w-auto text-center space-y-3">
          <FontAwesomeIcon
            icon={faComments}
            className="text-primary text-4xl"
          />
          <p className="font-bold text-2xl mx-auto">Messagerie interprofessionnelle</p>
          <p className="font-light text-xl min-h-16 ">
          Sécurisée 
            <br /> Instantanée
            <br /> Adaptée à tous les modes d’exercice
            <br />
          </p>
        </div>

        <div className="m-auto hover:bg-primary/10 rounded-md transition-all duration-400 p-10 w-full xl:w-auto text-center">
          <FontAwesomeIcon
            icon={faCreditCard}
            className="text-primary text-4xl"
          />
          <p className="font-bold text-2xl mx-auto">Paiement en ligne</p>
          <p className="font-light text-xl">
            Avec ou sans empreinte bancaire
            <br /> Immédiat sur votre compte
            <br /> Envoi du lien par courriel et SMS
          </p>
        </div>
      </div>

      {/* PRICING SECTION */}
      <div className="py-20 px-2 mx-auto w-full">
        <h2 className="text-greys-150 text-3xl font-bold mt-6 mb-3 text-center">
          Abonnements
        </h2>
        <p className="font-extralight mb-3 text-center">
          Engagement sur 12 mois une fois la période d'essai terminée
        </p>

        <div className=" px-2 mx-auto w-full">
          <div className="flex justify-evenly items-center m-8">
            <div className="w-44 h-[20%] lg:h-[50%] rounded-2xl border-[1px] border-greys-30 flex items-center justify-evenly cursor-pointer">
              <div
                className={`w-full h-[80%] flex items-center justify-center rounded-xl ${
                  pricingType === "mensuel" ? "bg-primary text-white" : ""
                }`}
                onClick={() => setPricingType("mensuel")}
              >
                <p>Mensuel</p>
              </div>
              <div
                className={`w-full h-[80%] flex items-center justify-center rounded-xl ${
                  pricingType === "annuel" ? "bg-primary text-white" : ""
                }`}
                onClick={() => setPricingType("annuel")}
              >
                <p>Annuel</p>
              </div>
            </div>
          </div>
          <div className="h-[100%] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="h-full w-full flex justify-center items-center">
              <div className="h-[100%] w-[95%] bg-white border border-greys-30 rounded flex justify-center items-baseline px-1 py-16">
                <div className="h-max w-[85%] flex flex-col gap-3">
                  <h1 className="text-xl uppercase text-center">
                    Téléexpertise et messagerie
                  </h1>
                  {pricingType === "mensuel" ? (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">0€</span> /mois
                    </p>
                  ) : (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">0€</span> /an
                    </p>
                  )}
                  <p className="font-extralight mb-3 text-center">
                    Sans engagement
                  </p>
                  <Buttons
                    preset={"secondary"}
                    text={"Découvrir"}
                    link={
                      "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=8&Itemid=181"
                    }
                  />
                  <p className="font-extralight text-sm mt-3">
                    <b>
                      Pour requis et requérant, en exercice individuel comme en
                      organisation (CPTS, MSP, ESS, ESP, établissements…)
                    </b>
                  </p>
                  <ul className="leading-8">
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Nombre illimité de téléexpertises
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Questionnaires médicaux spécialisés
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Partage d’images et fichiers DICOM
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Messagerie interprofessionnelle sécurisée
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="h-full w-full flex justify-center items-center">
              <div className="h-[100%] w-[95%] bg-white border border-greys-30 rounded flex justify-center items-baseline px-1 py-16">
                <div className="h-max w-[85%] flex flex-col gap-y-3">
                  <h1 className="text-xl uppercase text-center">
                    Télémédecine
                  </h1>
                  {pricingType === "mensuel" ? (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">55€</span> /mois
                    </p>
                  ) : (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">540€</span> /an
                    </p>
                  )}
                  <p className="font-extralight mb-3 text-center">
                    Essai de 14 jours sans engagement
                  </p>
                  <Buttons
                    preset={"secondary"}
                    text={"Découvrir"}
                    link={
                      "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=8&Itemid=181"
                    }
                  />
                  <p className="font-extralight text-sm mt-3">
                    <b>Abonnement "Téléexpertise et messagerie" incluse</b>
                  </p>
                  <ul className="leading-8">
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Téléconsultation en 3 clics
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Paiement en ligne par carte bancaire
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      E-RCP avec visio
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Partage de documents et messages avec le patient en dehors
                      des téléconsultations
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Formation incluse
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Assistance dédiée courriel et téléphone
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="h-[100%] w-full flex justify-center items-center">
              <div className="h-[100%] w-[95%] bg-white border-2 border-primary rounded flex justify-center items-baseline px-1 py-12">
                <div className="h-max w-[85%] flex flex-col gap-y-3">
                  <h1 className="text-xl uppercase text-center">
                    <span class="text-xs bg-primary text-white px-3 py-1 rounded uppercase">
                      Préféré
                    </span>
                    <div class="mt-3"></div>
                    Agenda rdv en ligne
                  </h1>
                  {pricingType === "mensuel" ? (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">55€</span> /mois
                    </p>
                  ) : (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">540€</span> /an
                    </p>
                  )}
                  <p className="font-extralight mb-3 text-center">
                    Essai de 14 jours sans engagement
                  </p>
                  <Buttons
                    preset={"primary"}
                    text={"Découvrir"}
                    link={
                      "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=8&Itemid=181"
                    }
                  />
                  <p className="font-extralight text-sm mt-3">
                    <b>Abonnement "Téléexpertise et messagerie" incluse</b>
                    <br />
                  </p>
                  <ul className="leading-8">
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Agenda individuel
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Agenda partagé multi-cabinets
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Rappel de rdv par courriel et SMS
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Import des rendez-vous
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Import de la base patients
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Licences secrétaires illimitées
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Formation incluse
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Assistance dédiée courriel et téléphone
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="h-full w-full flex justify-center items-center">
              <div className="h-[100%] w-[95%] bg-white border-[1px] border-greys-30  rounded flex justify-center items-baseline px-1 py-16 ">
                <div className="h-max w-[85%] flex flex-col gap-y-3">
                  <h1 className="text-xl uppercase text-center ">Full</h1>
                  {pricingType === "mensuel" ? (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">65€</span> /mois
                    </p>
                  ) : (
                    <p className="text-greys-150 text-center">
                      <span className="text-4xl font-bold">660€</span> /an
                    </p>
                  )}
                  <p className="font-extralight mb-3 text-center">
                    Essai de 14 jours sans engagement
                  </p>
                  <Buttons
                    preset={"secondary"}
                    text={"Découvrir"}
                    link={
                      "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=8&Itemid=181"
                    }
                  />
                  <p className="font-extralight text-sm mt-3">
                    <b>Tous les abonnements pour 10€/mois de plus</b>
                  </p>

                  <ul className="leading-8">
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Abonnement AGENDA RDV EN LIGNE
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Abonnement TELEMEDECINE
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Abonnement TELEEXPERTISE ET MESSAGERIE
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Formation incluse
                    </li>
                    <li className="font-light">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-xl mr-2"
                      />
                      Assistance dédiée courriel et téléphone
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT US */}
      <div id="quiSommesNous">
        <div className="text-center p-20 lg:py-60 bg-primary text-white">
          <div className="px-2 h-4/5 mx-auto w-full">
            <small className="font-light text-[20px]">QUI SOMMES NOUS ?</small>
            <p className="text-white font-bold mt-6 mb-3 text-3xl">
              MonMedecin.org est une plateforme, sécurisée et inter-régionale,
              <br /> de télémédecine et d'agenda
            </p>
            <p className="font-light text-xl">
              Créée par l'Union des Médecins Libéraux Nouvelle-Aquitaine
              <br />
              en collaboration avec l'URPS ML Hauts de France et l'URPS ML Grand
              Est
              <br />
              et aujourd'hui déployée sur l'ensemble du territoire français
            </p>
          </div>
        </div>

        {/* SECTION LOGOS URPS*/}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-20 gap-10 bg-greys-10">
          <a href="https://www.urpsml-na.org/" target="_blank" rel="noreferrer">
            <img
              className="grayscale hover:grayscale-0 transition-all"
              src={urpsLogoNa}
              alt="Logo URPS NA"
            />
          </a>
          <a href="https://www.urpsml-hdf.fr/" target="_blank" rel="noreferrer">
            <img
              className="grayscale hover:grayscale-0 transition-all"
              src={urpsLogoHdf}
              alt="Logo URPS HDF"
            />
          </a>

        </div>

        {/* SECTION PREUVE DE CONFIANCE */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 items-center justify-items-center py-20 gap-10">
          <a
            href="https://esante.gouv.fr/segur/solutions-referencees?search_api_fulltext=MonMedecin&field_categorie=All&field_type_logiciel=All"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="grayscale hover:grayscale-0 transition-all"
              width={130}
              src={ansLogo}
              alt="Logo ANS"
            />
          </a>
          <a
            href="https://www.synaaps.com/fr/certifications/certification-hds-et-agrement-hads.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="grayscale hover:grayscale-0 transition-all"
              width={180}
              src={hdsLogo}
              alt="Logo HADS"
            />
          </a>
          <a
            href="https://www.iso.org/fr/standard/27001"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="grayscale hover:grayscale-0 transition-all"
              width={300}
              src={isoLogo}
              alt="Logo ISO 27001"
            />
          </a>
          <a
            href="https://esante.gouv.fr/sites/default/files/media_entity/documents/Charte%20engage%CC%81%20pour%20la%20e%20sante%CC%81-12-01-22.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="grayscale hover:grayscale-0 transition-all"
              width={230}
              src={epesLogo}
              alt="Logo EPES"
            />
          </a>
          <a href="https://label-nr.fr/" target="_blank" rel="noreferrer">
            <img
              className="grayscale hover:grayscale-0 transition-all"
              width={130}
              src={nrLogo}
              alt="Logo NR"
            />
          </a>
        </div>
      </div>

      {/* FOOTER */}
      <Footer isProLanding={true} />
    </div>
  );
};
