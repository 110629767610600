import { useState } from "react";
import { Buttons } from "../UIElements/UIElements";
import MMLogo from "./media/MM_logo.png";
import MMLogoBlanc from "./media/MM_logo_blanc.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

// isProLanding : BOOL,
export const Header = ({ isProLanding }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  // TOGGLE MENU OR CLOSE ONCLICK BUTTON
  const handleOnClick = () => {
    if (menuOpened) {
      setMenuOpened(false);
    } else {
      setMenuOpened(true);
    }
  };

  return (
    <div className={`${isProLanding ? "" : "bg-primary"} transition-all`}>
      <div
        className={`flex justify-between items-center px-8 py-8 max-w-screen-2xl mx-auto w-full`}
      >
        {/* MM LOGO */}
        <div>
          <a href="">
            <img
              src={`${isProLanding ? MMLogo : MMLogoBlanc}`}
              alt="mm-logo"
              className="hover:-translate-y-1 transition-all w-52 md:w-72"
            />
          </a>
        </div>
        <div>
          {/* DISPLAY MENU ON LANDING PAGE PRO / PATIENT */}
          <div
            className={`${
              !menuOpened
                ? "hidden"
                : "overflow-hidden flex flex-col justify-evenly items-center p-16 w-full bg-white border border-black absolute right-0 top-0 md:hidden z-50"
            } lg:flex gap-3 ${isProLanding ? "pt-24 lg:pt-0" : ""}`}
          >
            {!isProLanding && (
              <Buttons
                text={"S'inscrire"}
                onClick={() => handleOnClick()}
                link={
                  isProLanding
                    ? ""
                    : "https://www.monmedecin.org/index.php?option=com_fabrik&view=form&formid=3&Itemid=180"
                }
                styles={
                  menuOpened
                    ? "text-primary hover:underline"
                    : "text-white hover:underline"
                }
              />
            )}
            <Buttons
              preset={"primary"}
              text={"Se connecter"}
              icon={faArrowUpRightFromSquare}
              link={
                "https://www.monmedecin.org/index.php?option=com_users&view=login&Itemid=170"
              }
              styles={"w-[80vw] md:w-full hover:-translate-y-1"}
            />
            <Buttons
              preset={"secondary"}
              text={
                isProLanding
                  ? "Vous êtes un patient ?"
                  : "Vous êtes un professionnel ?"
              }
              link={isProLanding ? "/" : "/pro"}
              styles={`w-[80vw] md:w-full hover:-translate-y-1 ${
                true ? "animate-pulse " : " "
              } bg-white`}
            />
          </div>

          {/* MOBILE : BUTTON FOR OPEN AND CLOSE MENU */}
          <div className="lg:hidden">
            <button
              className={`
              ${!menuOpened & !isProLanding ? "text-white" : "text-primary"}
              ${
                isProLanding
                  ? "text-primary bg-transparent outline outline-1 outline-primary transition-all hover:underline items-center w-12 h-12 px-1 rounded-lg z-[900] absolute right-8 top-8"
                  : "bg-transparent outline outline-1 outline-white transition-all hover:underline items-center w-12 h-12 px-1 rounded-lg z-[900] absolute right-8 top-8"
              }
                }`}
              onClick={() => handleOnClick()}
            >
              {menuOpened ? (
                <FontAwesomeIcon icon={faClose} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
