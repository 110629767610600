import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const Buttons = ({ preset, styles, text, icon, link, action }) => {
  /**
   * BUTTONS WILL NEED:
   * - PRESET (primary || secondary)
   * - STYLES (added to the predefined button),
   * - TEXT (visible on button),
   * - ICON (FontAwsome imported on another component),
   * - LINK,
   * - ACTION (the action this button will take => onClick || onChange || onSubmit ...)
   **/
  switch (preset) {
    default:
      return (
        <button>
          <a
            href={link}
            onClick={action}
            className={
              "text-center px-8 py-2 rounded gap-3 flex items-center justify-center transition duration-300 " +
              styles
            }
          >
            {text}
            {icon && <FontAwesomeIcon icon={icon} />}
          </a>
        </button>
      );
    case "primary":
      return (
        <button>
          <a
            href={link}
            onClick={action}
            className={
              `text-center px-8 py-2 rounded gap-3 flex items-center justify-center text-white bg-primary outline outline-1 outline-primary transition-all hover:bg-primary/90 ` +
              styles
            }
          >
            {text}
            {icon && <FontAwesomeIcon icon={icon} />}
          </a>
        </button>
      );
    case "secondary":
      return (
        <button>
          <a
            href={link}
            onClick={action}
            className={
              `text-center px-8 py-2 rounded gap-3 flex items-center justify-center text-primary bg-transparent outline outline-1 outline-primary transition-all hover:bg-gray-100 ` +
              styles
            }
          >
            {text}
            {icon && <FontAwesomeIcon icon={icon} />}
          </a>
        </button>
      );
  }
};

export const Inputs = ({ type, style, value, placeholder, onChange }) => {
  /**
   * INPUTS WILL NEED:
   * - TYPE => TEXT, DATE, PASSWORD...,
   * - STYLE => ADDED TO PREDEFINED STYLE,
   * - VALUE => INPUT VALUE,
   * - PLACEHOLDER => TEXT INDICATIVE OF THE FIELD CONCERNED
   **/
  return (
    <input
      type={type}
      className={
        "pe-20 py-2 placeholder:text-[16px] focus:outline-none " + style
      }
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export const Forms = ({ preset, styles }) => {
  const [place, setPlace] = useState({
    filtre_nom_pro: "",
    filtre_ville: "",
    coordgps_latitude: 0,
    coordgps_longitude: 0,
  });
  const navigate = useNavigate();
  const url =
    "https://monmedecin.org/index.php?filtre_nom_pro=" +
    place.filtre_nom_pro +
    "&filtre_ville=" +
    place.filtre_ville +
    "&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=$" +
    place.coordgps_latitude +
    "&coordgps_longitude=$" +
    place.coordgps_longitude +
    "&borneDispo=&borneDistance=&modeRdv=";

  // Redirect url with datas
  const handleSubmit = () => {
    /*window.location.href = url;
    console.log(url);*/
    /*navigate(
      "https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=",
      { replace: true }
    );
    window.location.href =
      "https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=";*/
    /*console.log(
      "https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=" +
        place.filtre_ville +
        "&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=$" +
        place.coordgps_latitude +
        "&coordgps_longitude=$" +
        place.coordgps_longitude +
        "&borneDispo=&borneDistance=&modeRdv="
    );
    console.log("place.filtre_nom_pro " + place.filtre_nom_pro);
    console.log("place.filtre_ville " + place.filtre_ville);*/
    if (!Boolean(place.filtre_nom_pro) && !Boolean(place.filtre_ville)) {
      console.log("NI NI");
      window.location.href = url;
      window.location.href =
        "https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=";

      /*navigate(
        `index.php?filtre_nom_pro=&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=`
      );*/
    } else if (!Boolean(place.filtre_nom_pro)) {
      /*navigate(
        "https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=" +
          place.filtre_ville +
          "&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=" +
          place.coordgps_latitude +
          "&coordgps_longitude=" +
          place.coordgps_longitude +
          "&borneDispo=&borneDistance=&modeRdv=",
        { replace: true }
      );*/

      navigate(
        `https://monmedecin.org/index.php?filtre_nom_pro=&filtre_ville=${encodeURIComponent(
          place.filtre_ville
        )}&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=${
          place.coordgps_latitude
        }&coordgps_longitude=${
          place.coordgps_longitude
        }&borneDispo=&borneDistance=&modeRdv=`,
        { replace: true }
      );
    } else if (!Boolean(place.filtre_ville)) {
      window.location.href =
        "https://monmedecin.org/index.php?filtre_nom_pro=" +
        place.filtre_nom_pro +
        "&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=$&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=";

      /*navigate(
        `index.php?filtre_nom_pro=${encodeURIComponent(
          place.filtre_nom_pro
        )}&filtre_ville=&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=&coordgps_longitude=&borneDispo=&borneDistance=&modeRdv=`
      );*/
    } else {
      window.location.href =
        "https://monmedecin.org/index.php?filtre_nom_pro=" +
        place.filtre_nom_pro +
        "&filtre_ville=" +
        place.filtre_ville +
        "&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=$" +
        place.coordgps_latitude +
        "&coordgps_longitude=$" +
        place.coordgps_longitude +
        "&borneDispo=&borneDistance=&modeRdv=";

      /*navigate(
        `index.php?filtre_nom_pro=${encodeURIComponent(
          place.filtre_nom_pro
        )}&filtre_ville=${encodeURIComponent(
          place.filtre_ville
        )}&option=com_vikappointments&view=employeeslist&Itemid=169&employee_group=0&coordgps_latitude=${
          place.coordgps_latitude
        }&coordgps_longitude=${
          place.coordgps_longitude
        }&borneDispo=&borneDistance=&modeRdv=`
      );*/
    }
  };
  console.log(Boolean(place.filtre_nom_pro));

  console.log(place);

  switch (preset) {
    case "landingSearch": // FORMS FOR LANDING PATIENT SEARCH
      return (
        <form
          className={"flex p-2 rounded-lg w-[80vw] md:w-3/4 " + styles}
          onSubmit={handleSubmit}
        >
          <div className="bg-white flex flex-col p-2 rounded-lg lg:flex-row w-full">
            <Inputs
              type="text"
              placeholder={"Nom, profession, spécialité"}
              style={"py-5 px-1 lg:py-2  w-full"}
              onChange={(e) =>
                setPlace((prev) => ({
                  ...prev,
                  filtre_nom_pro: e.target.value,
                }))
              }
            />
            <Autocomplete
              placeholder="Où ?"
              className="py-5 px-1 lg:py-2  w-full focus:outline-none"
              apiKey={"AIzaSyDEjubE4Djjgi9W_mKGzuVp4dtBZgZTPK8"}
              onPlaceSelected={(place) => {
                setPlace((prev) => ({
                  ...prev,
                  filtre_ville: place.formatted_address,
                  coordgps_latitude: place.geometry.location.lat(),
                  coordgps_longitude: place.geometry.location.lng(),
                }));
              }}
            />
            <Buttons
              type={"submit"}
              text={"Rechercher"}
              preset={"primary"}
              styles={"rounded py-2"}
            />
          </div>
        </form>
      );
  }
};

export const ModalCookie = ({ acceptCookie }) => {
  /**
   * acceptCookie => function will set Cookies when click on accept button
   */
  return (
    <>
      {/* OVERLAY */}
      <div className={`fixed inset-0 bg-black opacity-60 z-40 `}></div>
      {/* // <!-- MAIN MODAL --> */}
      <div
        className={`overflow-y-auto overflow-x-hidden fixed top-0 max-md:lg:top-1/2 max-md:lg:left-1/2 z-[999] md:lg:z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full animate-slideBtm transition-all`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* <!-- MODAL CONTENT --> */}
          <div className="relative bg-white rounded-lg shadow ">
            {/* <!-- MODAL HEADER --> */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-primary">
                Politique des cookies
              </h3>
            </div>
            {/* <!-- MODAL BODY --> */}
            <div className="p-4 md:p-5 space-y-4">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                MonMedecin.org attache une attention particulière à la
                protection des données.
              </p>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                Les cookies présents sur la plateformes sont nécessaires à des
                fins techniques et de bon fonctionnement. Ils sont donc
                dispensés de consentement.
              </p>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                MonMedecin.org ne revend, ni partage, ni transfère hors EU
                aucune donnée de santé.
              </p>
            </div>
            {/* <!-- MODAL FOOTER --> */}
            <div className="flex max-sm:flex-col items-center p-4 md:p-5 border-t border-gray-200 rounded-b gap-3">
              <Buttons
                text={"J'accepte"}
                preset={"primary"}
                action={acceptCookie}
              />
              <Link to="https://www.monmedecin.org/index.php?option=com_content&view=article&id=25&Itemid=165">
                <Buttons text={"En savoir plus"} preset={"secondary"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Modal = ({ title, texts, handleOpen, handleClose, isOpened }) => {
  /**
   * title => title of modal
   * texts => array of sentences displayed on modal content
   * handleOpen => title of modal
   * handleClose => title of modal
   */

  // GET ALL ELEMENT OF TEXTS AND PUSH ON TEXT ARRAY
  const text = [];
  texts.forEach((t) => {
    text.push(t);
  });

  console.log(text);
  return (
    <>
      {/* OVERLAY */}
      <div className="fixed inset-0 bg-black opacity-60 z-40"></div>
      {/* // <!-- MAIN MODAL --> */}
      <div
        className={`${
          !isOpened ? "hidden" : ""
        } overflow-y-auto overflow-x-hidden fixed top-0 max-md:lg:top-1/2 max-md:lg:left-1/2 z-[999] md:lg:z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full animate-slideBtm transition-all`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* <!-- CONTENT --> */}
          <div className="relative bg-white rounded-lg shadow ">
            {/* <!-- MODAL HEADER --> */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-primary">{title}</h3>
            </div>
            {/* <!-- MODAL BODY : display all element of text array --> */}
            <div className="p-4 md:p-5 space-y-3">
              {text.map((t) => {
                return (
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                    {t}
                  </p>
                );
              })}
            </div>
            {/* <!-- MODAL FOOTER --> */}
            <div className="flex max-sm:flex-col items-center p-4 md:p-5 border-t border-gray-200 rounded-b gap-3">
              <Buttons
                text={"J'accepte"}
                preset={"primary"}
                action={handleOpen}
              />
              <Buttons
                text={"Fermer"}
                preset={"secondary"}
                action={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
