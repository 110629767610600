// IMPORT DES ENTETES ET PIED DE PAGES
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
// IMPORT DES IMAGES
import ansLogo from "./media/partners-logo/ans-logo.png"; // Logo de l'ANS
import hdsLogo from "./media/partners-logo/hds-logo.png"; // Logo de HDS
import isoLogo from "./media/partners-logo/iso-logo.png"; // Logo ISO 27001
import epesLogo from "./media/partners-logo/epes-logo.png"; // Logo Engagé pour la E-Santé
import nrLogo from "./media/partners-logo/nr-logo.png"; // Logo Numérique responsable
import urpsLogoHdf from "./media/partners-logo/urps-logo-hdf.png"; // Logo URPS Haut-De-France
import urpsLogoNa from "./media/partners-logo/urps-logo-na.png"; // Logo URPS Nouvelle-Aquitaine
import urpsLogoGE from "./media/partners-logo/urps-logo-ge.png"; // Logo URPS Grand Est
import charUrps from "./media/landing_page.png"; // Image
// IMPORT DES ICONES FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Buttons } from "../../components/UIElements/UIElements";

import {
  faVideo,
  faCalendar,
  faFileImport,
  faShield,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Forms } from "../../components/UIElements/UIElements";

// IMPORT DE LIBRAIRIES
import DocumentMeta from "react-document-meta";

export const LandingPatient = () => {
  const metaContent = {
    title: "MonMedecin.org • La solution de télémédecine éthique et solidaire",
    description:
      "MonMedecin.org est une plateforme complète de télémédecine et d'agenda, conçue pour répondre aux besoins des médecins libéraux et autres professionnels de santé, afin de faciliter leur exercice professionnel et d'améliorer l’accès aux soins.",
    canonical: "https://monmedecin.org/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "MonMedecin,Télémédecine,Téléconsultation,Téléexpertise,e-rcp,agenda,messagerie",
      },
    },
  };

  return (
    <div className="">
      <DocumentMeta {...metaContent} />
      <Header isProLanding={false} />

      {/* GREY BACKGROUND */}
      <div className="flex flex-col items-center max-h-[85%] h-screen w-full absolute -z-[51] bg-primary">
        <img
          src={charUrps}
          alt="char-urps"
          className=" h-full scale-x-[250%] sm:scale-x-[225%] md:scale-x-150 lg:scale-100 m-auto -z-[999] bg-primary opacity-40 w-[15]  "
        />
      </div>

      {/* HERO SECTION */}
      <div className="flex flex-col items-center p-20 lg:p-40 h-[800px] lg:h-[550px]">
        <div className="my-12 flex flex-col gap-1 text-center">
          <h2 className="py-1 px-12 w-[90vw] md:lg:w-full text-white text-xl md:lg:text-3xl font-semibold">
            MonMedecin.org votre plateforme
          </h2>
          <h2 className="py-1 px-12 w-[90vw] md:lg:w-full text-white text-xl md:lg:text-3xl font-semibold">
            de rendez-vous et de téléconsultation
          </h2>
        </div>
        <Buttons
          text={"Prendre un rendez-vous"}
          link={
            "https://www.monmedecin.org/index.php?option=com_vikappointments&view=employeeslist&Itemid=169"
          }
          icon={faSearch}
          styles={"text-primary bg-white hover:underline text-xl"}
        />
      </div>

      {/* ABOUT US */}
      <div className="text-center mt-12 lg:pt-72 lg:pb-40">
        <div className="px-2 max-w-screen-2xl mx-auto w-full">
          <small className="font-light text-[20px]">QUI SOMMES NOUS ?</small>
          <p className="font-bold mt-6 mb-3 text-3xl text-slate-800">
            MonMedecin.org est une plateforme, sécurisée et inter-régionale,
            <br /> de télémédecine et d'agenda
          </p>
          <p className="font-light text-xl">
            Créée par l'Union des Médecins Libéraux Nouvelle-Aquitaine
            <br />
            en collaboration avec l'URPS ML Hauts de France et l'URPS ML Grand
            Est
            <br />
            et aujourd'hui déployée sur l'ensemble du territoire français
          </p>
        </div>
      </div>

      {/* ICON SECTION */}
      <div className="p-8 lg:p-40 bg-white lg:bg-primary text-greys-150 lg:text-white flex justify-center">
        <div className="grid grid-cols-2 lg:grid-cols-4 items-center gap-12 lg:gap-16">
          <div className="flex flex-col text-center font-semibold gap-5">
            <FontAwesomeIcon icon={faVideo} className="text-3xl" />
            <p className="lg:w-48 text-[14px] lg:text-[16px]">
              TÉLÉCONSULTATION SÉCURISÉE
            </p>
          </div>
          <div className="flex flex-col text-center font-semibold gap-5">
            <FontAwesomeIcon icon={faCalendar} className="text-3xl" />
            <p className="lg:w-48 text-[14px] lg:text-[16px]">
              PRISE DE RENDEZ-VOUS EN LIGNE
            </p>
          </div>
          <div className="flex flex-col text-center font-semibold gap-5">
            <FontAwesomeIcon icon={faFileImport} className="text-3xl" />
            <p className="lg:w-48 text-[14px] lg:text-[16px]">
              PARTAGE DE DOCUMENTS
            </p>
          </div>
          <div className="flex flex-col text-center font-semibold gap-5">
            <FontAwesomeIcon icon={faShield} className="text-3xl" />
            <p className="lg:w-48 text-[14px] lg:text-[16px]">
              DONNÉES <br />
              SÉCURISÉES
            </p>
          </div>
        </div>
      </div>

      {/* SECTION LOGOS URPS*/}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-20 gap-10 bg-greys-10">
        <a href="https://www.urpsml-na.org/">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            src={urpsLogoNa}
            alt="Logo URPS NA"
          />
        </a>
        <a href="https://www.urpsml-hdf.fr/">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            src={urpsLogoHdf}
            alt="Logo URPS HDF"
          />
        </a>
      </div>

      {/* SECTION PREUVE DE CONFIANCE */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 items-center justify-items-center py-20 gap-10">
        <a href="https://esante.gouv.fr/segur/solutions-referencees?search_api_fulltext=MonMedecin&field_categorie=All&field_type_logiciel=All">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            width={130}
            src={ansLogo}
            alt="Logo ANS"
          />
        </a>
        <a href="https://www.synaaps.com/fr/certifications/certification-hds-et-agrement-hads.html">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            width={180}
            src={hdsLogo}
            alt="Logo HADS"
          />
        </a>
        <a href="https://www.iso.org/fr/standard/27001">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            width={300}
            src={isoLogo}
            alt="Logo ISO 27001"
          />
        </a>
        <a href="https://esante.gouv.fr/sites/default/files/media_entity/documents/Charte%20engage%CC%81%20pour%20la%20e%20sante%CC%81-12-01-22.pdf">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            width={230}
            src={epesLogo}
            alt="Logo EPES"
          />
        </a>
        <a href="https://label-nr.fr/">
          <img
            className="grayscale hover:grayscale-0 transition-all"
            width={130}
            src={nrLogo}
            alt="Logo NR"
          />
        </a>
      </div>

      <Footer isProLanding={false} />
    </div>
  );
};
