import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ModalCookie } from "../UIElements/UIElements";

const CookiesConsent = () => {
  const [cookies, setCookie] = useCookies(["cookiesConsent"]);
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    if (!cookies.cookiesConsent) {
      setShowConsent(true);
    }
  }, [cookies.cookiesConsent]);

  const giveCookiesConsent = () => {
    const currentDate = new Date();
    currentDate.setUTCMonth(currentDate.getUTCMonth() + 6);
    const cookieExpirationDate = currentDate;

    setCookie("cookiesConsent", 'token', {
      expires: cookieExpirationDate,
      path: "/",
    });

    setShowConsent(false);
  };

  return (
    <div>
      {showConsent && (
        <ModalCookie acceptCookie={giveCookiesConsent} />
      )}
    </div>
  );
};

export default CookiesConsent;