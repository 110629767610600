import { NavLink } from "react-router-dom";

export const About = () => {
  return (
    <div>
      <h1>About</h1>
      <NavLink to={'/'}>Home</NavLink>
    </div>
  );
};
