import { useState } from "react";
import { Buttons, Forms, Modal, ModalCookie } from "../../components/UIElements/UIElements";
import { faCode, faSmile, faAngry, faMeh } from "@fortawesome/free-solid-svg-icons";

export const PreviewUI = () => {
  const [isOpened, setIsOpened] = useState(true);

  const phrase1 =
    "Certains cookies sont nécessaires à des fins techniques, ils sont donc dispensés de consentement. D'autres, non obligatoires, peuvent être utilisés pour assurer une meilleureexpérience d'utilisation ou pour accéder à certaines fonctionnalités.";

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <>
      <div className="text-center">
        <h1>Buttons</h1>
      </div>
      <div className="flex justify-center mt-5">
        {/* BUTTONS */}
        <div className="grid grid-cols-3 gap-3">
          {/* Buttons w/ presets */}
          <Buttons preset={"primary"} text={"Primary"} icon={faCode} />
          <Buttons preset={"secondary"} text={"Primary"} icon={faCode} />
          {/* Default buttons */}
          <Buttons text={"Default"} styles={"text-white bg-[#2c3e50]"} icon={faCode} />
          {/* Custom Buttons */}
          <Buttons text={"Success"} styles={"text-white bg-success-type"} icon={faSmile} />
          <Buttons text={"Error"} styles={"text-white bg-error-type"} icon={faAngry} />
          <Buttons text={"Warning"} styles={"text-white bg-warning-type"} icon={faMeh} />
        </div>
      </div>

      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
      </p>
      <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
      </p>

      <div className="flex justify-center mt-5">
        <Forms preset={"landingSearch"} />
      </div>

      {/* MODAL */}
      <div className="flex justify-center mt-5 gap-10">
        {/* OPEN MODAL */}
        <Buttons preset={"secondary"} text={"Open Modal"} />

        {/* CUSTOM MODAL */}
        <Modal title={"Modal Default"} texts={[phrase1, phrase1, phrase1]} handleOpen={handleOpen} handleClose={handleClose} isOpened={isOpened} />

        {/* <Modals /> */}
      </div>
    </>
  );
};
